.auth-form-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 16px;
}

.auth-form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    text-align: center;
    margin-bottom: 0;
  }

  form {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .server-error {
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  mat-form-field {
    margin-bottom: 10px;
  }

  mat-error span {
    padding-right: 16px;
  }

  .sign-up {
    margin-top: 32px;
    padding-bottom: 16px;
    text-align: center;

    a {
      margin-left: 4px;
    }
  }
}
